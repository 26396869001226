import React, { useState, setState, useContext } from 'react';
import logo from './logo.svg';
import './App.css';
import GoogleMap from './GoogleMap'


function App() {
  const [fetchingData, setFetchingData] = useState(false)
  const [brandMenu, setBrandMenu] = useState([])
  const [selectedBrand, setSelectedBrand] = useState()
  const [brandList, setBrandList] = useState([])
  const [stateData, setStateData] = useState()

  const onMapClicked = (e) => {
    console.log('click')
  }

  const style = {
    width: '100%',
    height: '100%'
  }

  const containerStyle = {
    position: 'relative',  
    width: '100%',
    height: '100%'
  }

  const getData = async () => {
    if(!fetchingData) {
      setFetchingData(true)
      fetch(`https://www.lionleaf.tech/api/v2/map/`)
      .then(response=>response.json())
      .then(json=>{
        // const MichiganData = json.report.report.filter((menuDetails)=>{
        //   return menuDetails.state === 'michigan'?true:false
        // })
        setStateData(json.menuData)
        buildBrandList(json.menuData)
      })
      .catch(error=>setStateData([]))
    }
  }

  const buildBrandList = (menuData) => {
    const brandList = menuData.reduce((aggregate, cur)=>{
      if(aggregate.indexOf(cur.brand)==-1) {
        aggregate.push(cur.brand)
      }
      return aggregate
    },[])
    setBrandList(brandList)
  }

  const selectBrandLocations = (e) => {
    const selectedBrandName = e.currentTarget.value
    if(selectedBrandName == '') return
    const curBrandData = stateData.filter(brandBlock=>{
      return selectedBrandName === brandBlock.brand ? true:false
    })
    setBrandMenu(curBrandData[0])
    setSelectedBrand(selectedBrandName)
  }

  if(!fetchingData) {
    getData()
  }

  return (

      <div className="App">
        <div className="ControlPanel">
          SELECT BRAND<br />
          <select onChange={selectBrandLocations}>
            <option></option>
            {brandList?brandList.map(brandName=>(
              <option key={brandName}>{brandName}</option>
            )):null}
          </select>
        </div>
          <GoogleMap 
            containerStyle={containerStyle}
            curState={'Michigan'}
            google={window.google}
            style={style}
            initialCenter={{
              lat: 44.314842,
              lng: -85.602364
            }}
            markerList={selectedBrand?brandMenu.menuData:null}
            zoom={15}
            onClick={onMapClicked}
          />
      </div>

  );
}

export default App;
