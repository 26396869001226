import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import  {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';

export class MapContainer extends React.Component {
    state = {
        showingInfoWindow: false,
        activeMarker: {},
        markerData: [],
        selectedPlace: {},
    }
    onMarkerClick = (props, marker, e) => {
        console.log(props)
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            markerData: props.menuData,
            showingInfoWindow: true
        });
    }
    onMapClicked = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
            showingInfoWindow: false,
            activeMarker: null
            })
        }
    };
    constructor(props) {
        super(props)
    }
    render() {
      return (
        <Map 
            google={this.props.google} 
            zoom={7}
            initialCenter={this.props.initialCenter}
        >
         {this.props.markerList ? this.props.markerList.map(nextMarker=>{
             return (
                 <Marker 
                    key={(nextMarker.lat+nextMarker.lng+Math.random())}
                    name={nextMarker.name}
                    address={nextMarker.address}
                    city={nextMarker.city}
                    state={this.props.curState}
                    zipcode={nextMarker.zipcode}
                    phone={nextMarker.phone}
                    menuData={nextMarker.menuData}
                    position={{lat:nextMarker.lat,lng:nextMarker.lng}}
                    onClick={this.onMarkerClick}
                 />
             )
         }):null}
            <InfoWindow
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}>
                <div>
                    <h3 style={{padding: '5px', margin:0}}>{this.state.selectedPlace.name}</h3>
                    <div style={{display:'flex', flexDirection:'column', margin: '5px'}}>
                        <div>{this.state.selectedPlace.address}</div>
                        <div>{this.state.selectedPlace.city}, {this.state.selectedPlace.state}</div>
                        <div>{this.state.selectedPlace.zipcode} {this.state.selectedPlace.phone} </div>
                    </div>      
                    <div>
                        {this.state.markerData.map((data,index)=>(
                            <div style={{padding: 10, backgroundColor:(index%2?'#fff':'#eee'), textAlign:'left', display: 'flex', flexDirection:'row'}}>
                                <div style={{flex:2}}>
                                    <div>Category: {data.category}</div>
                                    <div style={{fontWeight:'bold'}}>{data.name}</div>
                                    {data.prices.unit?(
                                        <div>${data.prices.unit.price} {data.prices.unit.label}</div>
                                    ):null}
                                </div>
                                <div style={{flex:1, justifyContent:'center'}}>
                                    <button style={{}}>BUY NOW</button>
                                </div>

                            </div>
                        ))}
                    </div>
                </div>
            </InfoWindow>
        </Map>
      );
    }
  }

export default GoogleApiWrapper(
    (props) => ({
      apiKey: 'AIzaSyD6i0rj8MamJb_dgQ3lEsP6e0ZCAI6dQyc',
    //   language: props.language,
    }
))(MapContainer)